import {
  Appointment,
  AppointmentDelta,
  useCreateAppointment,
  useDeleteAppointment,
  useUpdateAppointment,
} from 'app/api/AppointmentApi';
import React, { useState } from 'react';
import { FormActionProps } from '../../Forms/FormActionProps';
import { InputGroup } from '../../Forms/InputGroup';
import { EnterForm } from '../../Forms/EnterForm';
import { ButtonRowV2 } from '../../Forms/ButtonRowV2';
import { Glyph, TextAreaGroup } from '../../Forms/TextAreaGroup';
import { PatientInputGroup } from '../../Forms/PatientInputGroup/PatientInputGroup';
import { AppointmentType } from './AppointmentType';
import { AppointmentStatus } from './AppointmentStatus';
import { useAppointmentFormContext } from '../../Schedule/AppointmentFormHook';
import { ScheduleDefinitionPickerScheduleType } from '../../Schedule/layout/ScheduleDefinitionPickerScheduleType';
import { ScheduleDefinitionPickerClinicLocation } from '../../Schedule/layout/ScheduleDefinitionPickerClinicLocation';
import { DynamicAppointmentMultiSection } from './DynamicAppointmentMultiSection';
import { AppointmentPatientValidationHandler } from './AppointmentPatientValidationHandler';

export const notesGlyphs: Glyph[] = [
  { value: '📝' },
  { value: '✔' },
  { value: '🦴' },
  { value: '🚫' },
  { value: '📄' },
  { value: '📠' },
  { value: '📃' },
  { value: '✅' },
  { value: '⛳' },
  { value: '🚨' },
  { value: '🚩' },
  { value: '📑' },
];

export type AppointmentFormProps = FormActionProps<Appointment> & { openDemographics: () => void };

export const AppointmentForm = ({
  onSuccess,
  onCancel: _onCancel,
  onUnrecoverableError,
  onDeleted,
  openDemographics,
}: AppointmentFormProps) => {
  const {
    scheduleTypeIdWatch,
    clinicLocationIdWatch,
    scheduleTypeId,
    clinicLocationId,
    patient,
    setPatient,
    patientId,
    timeField,
    dateField,
    scheduleDefinitionIdWatch,
    appointmentTypeId,
    appointmentTypeIdWatch,
    appointmentStatusId,
    appointmentStatusIdWatch,
    appointmentIdWatch,
    handleSubmit,
    setError,
    durationMinutes,
    appointmentNote,
    additionalInfo,
    patientGlobalId,
    patientPhone1,
    patientClinicLocationId,
    additionalAppointmentCount,
  } = useAppointmentFormContext();

  const isNew = !appointmentIdWatch;

  const createAppointment = useCreateAppointment();
  const updateAppointment = useUpdateAppointment();
  const deleteAppointment = useDeleteAppointment();
  const apiAction = isNew ? createAppointment : updateAppointment;
  const [isDisabled, setIsDisabled] = useState(false);
  const submitText = isNew ? 'Create' : 'Update';

  const onSubmit = async (data: any) => {
    const appointment = await apiAction.mutateAsync(data);
    if (isNew) {
      onSuccess(appointment as Appointment);
    } else {
      onSuccess((appointment as AppointmentDelta).new);
    }
  };

  const onError = (err: any) => {
    throw err;
  };

  const onDelete = async () => {
    if (!isNew) {
      await deleteAppointment.mutateAsync(appointmentIdWatch);
    }
    if (onDeleted) {
      // I don't think we're using the parameter to onDeleted.
      onDeleted({ id: appointmentIdWatch } as Appointment);
    }
  };

  return (
    <div className="h-full w-full">
      <EnterForm
        submitForm={handleSubmit(onSubmit, onError)}
        setError={setError}
        onUnrecoverableError={onUnrecoverableError}
        setDisabled={setIsDisabled}
      >
        <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
          <div className="col-span-1">
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 lg:grid-cols-16">
              <div className="lg:col-span-9">
                <PatientInputGroup {...patientId} patient={patient} setPatient={setPatient} />
              </div>

              <div className="lg:col-span-7">
                <InputGroup {...patientGlobalId} type="text" placeholder="" readonly={true} bgColor="gray-100" />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
              <div className="col-span-1">
                <InputGroup {...dateField} type="date" placeholder="" />
              </div>
              <div className="col-span-1">
                <InputGroup {...timeField} type="time" placeholder="" />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-1">
              <div className="col-span-1">
                <ScheduleDefinitionPickerClinicLocation
                  scheduleTypeIdWatch={scheduleTypeIdWatch}
                  clinicLocationId={clinicLocationId}
                  clinicLocationIdWatch={clinicLocationIdWatch}
                  absolutePositioning={true}
                />
              </div>
              <div className="col-span-1">
                <ScheduleDefinitionPickerScheduleType
                  scheduleTypeId={scheduleTypeId}
                  clinicLocationIdWatch={clinicLocationIdWatch}
                  scheduleTypeIdWatch={scheduleTypeIdWatch}
                  absolutePositioning={true}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
              <div className="col-span-1">
                <AppointmentType
                  scheduleDefinitionId={scheduleDefinitionIdWatch}
                  appointmentType={appointmentTypeId}
                  value={appointmentTypeIdWatch}
                />
              </div>
              <div className="col-span-1">
                <AppointmentStatus
                  appointmentTypeId={appointmentTypeIdWatch}
                  appointmentStatus={appointmentStatusId}
                  value={appointmentStatusIdWatch}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-2">
              <div className="col-span-1">
                <InputGroup {...durationMinutes} type="number" placeholder="15" />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <TextAreaGroup
              {...additionalInfo}
              placeholder="More..."
              rows={3}
              tooltip="This value is not visible from the schedule"
            />
            <TextAreaGroup
              {...appointmentNote}
              placeholder="Something about this appointment..."
              rows={4}
              tooltip="This value is visible from the schedule"
            />
          </div>
        </div>
        <>{isNew && <DynamicAppointmentMultiSection fieldName="additionalAppointments" />}</>
        <div className="mt-2">
          <AppointmentPatientValidationHandler
            patientPhone1={patientPhone1}
            patientClinicLocationId={patientClinicLocationId}
            openDemographics={openDemographics}
            additionalAppointmentCount={additionalAppointmentCount}
          />
        </div>
        <div className="flex justify-end mt-4">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText={submitText}
            onDelete={isNew ? undefined : onDelete}
          />
        </div>
      </EnterForm>
    </div>
  );
};
